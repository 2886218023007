import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  TextField,
  Container,
  Paper,
  styled,
  Button
} from "@mui/material";
import { Props } from "./HelpCentreController.web";
import HelpCentreController from "./HelpCentreController.web";
import Layout from "../../../components/src/Layout.web";
import { icSearch, icTree } from "./assets";
import moment from "moment";

export default class HelpCentre extends HelpCentreController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
    <Layout navigation={this.props.navigation} currentRoute='HelpCentre'>
      <StyledContainer>
        <Container style={{ padding: 0, maxWidth: "unset"}}>
          <Box className="header-container">
          <Button
              onClick={() => this.onStartTour()}
              sx={{
                    width: '220px',
                    height: '44px',
                    background: 'linear-gradient(122.97deg, rgba(228, 201, 243, 1) -55.47%, #00184C 40.61%)',
                    borderRadius: '22px',
                    color: 'white',
                    border: "1px solid rgba(164, 223, 255, 1)",
                    textTransform: 'none',
                    fontSize: '14px',
                    fontWeight: 700,
                    position: "absolute",
                    right: "20px",
                    top: "20px"
                  }}
                >
                  Replay Onboarding Guide
                </Button>
            <img src={icTree.default} alt="icTree" style={{ marginTop: "45px", marginBottom: "12px", width: "86px", height: "86px", alignSelf: "center" }} />
            <Typography variant="h3" sx={{
              fontSize: "48px",
              fontWeight: 700,
              color: "#00184C",
              textAlign: "center",
              marginBottom: "16px",
            }}>
              How can we help you today?
            </Typography>
            <Typography sx={{
              fontSize: "16px",
              color: "#00184C",
              textAlign: "center",
              marginBottom: "32px",
              fontWeight: 400
            }}>
              Begin typing your question or keyword below for related articles.
            </Typography>

            <Box sx={{
              display: "flex",
              justifyContent: "center",
            }}>
              <Paper
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "600px",
                  borderRadius: "100px",
                  border: "1px solid #C4C4C6",
                  marginBottom: "45px",
                  height: "48px"
                }}
              >
                <img src={icSearch.default} alt="icSearch" style={{ width: "24px", height: "24px", marginLeft: "10px" }} />
                <TextField
                  fullWidth
                  value={this.state.searchText}
                  onChange={(e) => this.setState({searchText: e.target.value})}
                  placeholder="Search"
                  sx={{
                    "& .MuiInputBase-input": {
                      padding: "12px 16px",
                      color: "#00184C",
                      fontSize: "14px",
                      fontWeight: 700
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none"
                    }
                  }}
                />
              </Paper>
            </Box>

          </Box>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            padding: "45px"
          }}>
          <Typography variant="h5" sx={{
              fontSize: "24px",
              fontWeight: 700,
              color: "#00184C",
              marginBottom: "24px"
            }}>
              Suggested Articles
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px"}}>
              {this.state.helpCentreData.map((item: any) => (
                  <Paper sx={{
                    p: 3,
                    borderRadius: "8px",
                    cursor: "pointer",
                    "&:hover": {
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)"
                    }
                  }}
                  data-test-id="article-item"
                  onClick={() => this.props.navigation.navigate("HelpCentreDetails", {path: {id: item.id}, searchText: this.state.searchText})}
                  >
                   <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    justifyContent: "space-between",
                    alignItems: "center"
                   }}>
                    <Typography sx={{
                      fontSize: "18px",
                      fontWeight: 600,
                      color: "#00184C",
                      marginBottom: "8px"
                    }}>
                        {item.attributes.title}
                    </Typography>
                
                    <Typography sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#00184C",
                      marginBottom: "8px"
                    }}>
                      Last edit {moment(item.attributes.updated_at).fromNow()}
                    </Typography>
                    </Box>
                    <Typography sx={{
                      fontSize: "14px",
                      color: "#64748B",
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden"
                    }}>
                    {item.attributes.description}
                    </Typography>
                  </Paper>))}
              {this.state.helpCentreData.length === 0 && 
               <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "250px",
                gap: "8px"
               }}>
                <Typography style={{fontSize: "30px", fontWeight: 700, color: "linear-gradient(122.97deg, #FFA0A0 -55.47%, #4C0000 40.61%)"}}>Search result not found!</Typography>
                <Typography style={{fontSize: "18px", fontWeight: 400, color: "rgba(0, 24, 76, 1)"}}>Please try another search.</Typography>
               </Box>
              }
            </Box>
          </Box>
        </Container>
      </StyledContainer>
    </Layout>
    );
  }
}

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  backgroundColor: "#F8FAFC",
  margin: "-20px",

  "*": { 
    fontFamily: "Inter, sans-serif",
    boxSizing: "border-box"
  },

  ".header-container": {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(260.99deg, rgba(0, 191, 251, 0.5) -30.49%, rgba(255, 255, 255, 0) 41.08%, rgba(0, 191, 251, 0.5) 95.56%)"
  }
}));
// Customizable Area End
