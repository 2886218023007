import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  TextField,
  Paper,
  styled,
  Button,
  Menu,
  MenuItem
} from "@mui/material";
import { Props } from "./HelpCentreDetailsController.web";
import HelpCentreDetailsController from "./HelpCentreDetailsController.web";
import Layout from "../../../components/src/Layout.web";
import { ic_back } from "../../../blocks/accountgroups/src/assets";
import { icMenu, icSearch } from "./assets";
import moment from "moment";

export default class HelpCentreDetails extends HelpCentreDetailsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
    <Layout navigation={this.props.navigation} currentRoute='HelpCentre'>
      <StyledContainer>
        <Box className="header-container">
          <img onClick={() => this.props.navigation.navigate("HelpCentre")} src={ic_back.default} style={{width: "28px", position: "absolute",  height: "28px", top: "25px", left: "15px"}} />
          <Box sx={{
              justifyContent: "center",
              display: "flex",
            }}>
              <Paper
                sx={{
                  borderRadius: "100px",
                  border: "1px solid #C4C4C6",
                  marginBottom: "25px",
                  marginTop: "45px",
                  height: "48px",
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "788px",
                }}
              >
                <img src={icSearch.default} alt="icSearch" style={{ height: "24px", width: "24px", marginLeft: "10px" }} />
                <TextField
                  fullWidth
                  placeholder="Search"
                  value={this.state.searchText}
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: "14px",
                      color: "#00184C",
                      fontWeight: 700,
                      padding: "12px 16px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none"
                    }
                  }}
                />
              </Paper>
            </Box>
        </Box>

        <Box className="content-wrapper">
          <Box className="content-container">
            <Typography variant="h1" className="main-title">
              {this.state.helpCentreData?.attributes?.title}
            </Typography>
            <Typography variant="body2" className="timestamp">
            Last edit {moment(this.state.helpCentreData?.attributes?.updated_at).fromNow()}
            </Typography>
            
            <Typography variant="body1" className="content-text">
              {this.state.helpCentreData?.attributes?.description}
            </Typography>

            <Box className="attachments">
              {this.state.helpCentreData?.attributes?.files.map((item: any, index: number) => <Box className="file-item" key={index}>
                <Typography>{item.name}</Typography>
                <Typography>{item.size}</Typography>
                <Button className="more-options"
                  onClick={(event) => this.handleMenuClick(event, index)}
                ><img src={icMenu.default} alt="icMenu" style={{width: "24px", height: "24px"}}/></Button>
                <Menu
                  anchorEl={this.state.anchorEl}
                  open={this.state.selectedFileIndex === index}
                  onClose={this.handleMenuClose}
                >
                  <MenuItem onClick={() => this.handleDownload(item)}>Download</MenuItem>
                </Menu>
              </Box>)}
            </Box>
          </Box>

          <Box className="recommended-section">
            <Typography variant="h5" style={{fontSize: "18px", fontWeight: 400, color: "#00184C", marginBottom: "16px"}}>Additional Content</Typography>
            <Typography variant="h2">Recommended Articles</Typography>
            <Box className="recommended-links">
              {this.state.suggestedArticles.map((item: any, index: any) => (
                <Typography key={index} className="recommended-link" onClick={() => this.props.navigation.navigate("HelpCentreDetails", {path: {id: item.id}, searchText: this.state.searchText})}>
                  {item.attributes.title}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
      </StyledContainer>
    </Layout>
    );
  }
}

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "-20px",
  minHeight: "100vh",
  backgroundColor: "#F8FAFC",
  flex: 1,

  "*": { 
    fontFamily: "Inter, sans-serif",
    boxSizing: "border-box"
  },

  ".header-container": {
    display: "flex",
    position: "relative",
    width: "100%",
    alignItems: "center",
    flexDirection: "column",
  },

  ".content-wrapper": {
    display: "flex",
    padding: "10px 35px",
    margin: "0 auto",
    width: "100%",
    gap: "40px",
  },

  ".content-container": {
    flex: "1",
    borderRight: "1px solid  rgba(233, 233, 234, 1)",

    ".main-title": {
      fontSize: "24px",
      fontWeight: 700,
      color: "#00184C",
      marginBottom: "8px"
    },

    ".timestamp": {
      fontSize: "14px",
      color: "rgba(0, 24, 76, 1)",
      marginBottom: "24px",
      fontWeight: 400
    },

    ".content-text": {
      fontSize: "18px",
      color: "rgba(0, 24, 76, 1)",
      fontWeight: 400,
      marginBottom: "24px",
      lineHeight: "26px"
    },

    ".attachments": {
      marginBottom: "40px",
      display: "flex",
      flexWrap: "wrap",
      gap: "8px",
      
      ".file-item": {
        display: "flex",
        alignItems: "center",
        padding: "12px 16px",
        width: "327px",
        backgroundColor: "white",
        borderRadius: "8px",
        border: "1px solid rgba(233, 233, 234, 1)",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",

        "& > p:first-of-type": {
          flex: 1,
          fontSize: "14px",
          fontWeight: 500,
          color: "rgba(15, 23, 42, 1)"
        },
        
        "& > p:nth-of-type(2)": {
          marginRight: "16px",
          fontSize: "14px",
          color: "rgba(100, 116, 139, 1)"
        },
        
        ".more-options": {
          minWidth: "unset",
          padding: "4px",
          color: "#475467",
        }
      }
    }
  },

  ".recommended-section": {
    width: "350px",
    flexShrink: 0,
    
    "& > h2": {
      fontSize: "24px",
      fontWeight: 700,
      color: "#00184C",
      marginBottom: "16px"
    },

    ".recommended-links": {
      display: "flex",
      flexDirection: "column",
      gap: "12px",

      ".recommended-link": {
        fontSize: "18px",
        color: "rgba(0, 24, 76, 1)",
        cursor: "pointer",
        textDecoration: "underline",
        fontWeight: 400,

        "&:hover": {
          color: "#002EA8"
        }
      }
    }
  }
}));
// Customizable Area End
