Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "emaillists";
exports.labelBodyText = "emaillists Body";
exports.deleteApiMethodType = "DELETE"
exports.putApiMethodType = "PUT"

exports.btnExampleTitle = "CLICK ME";
exports.emailListCommonApiEndPoint = "/bx_block_emaillists/emaillists/"
exports.getAllEmailList2ApiEndPoint = "bx_block_emaillists/emaillists/"
exports.createEmailListApiEndPoint = "/bx_block_emaillists/emaillists/"
exports.editEmailListApiEndPoint = "/bx_block_emaillists/emaillists/"
exports.deleteEmailListApiEndPoint = "/bx_block_emaillists/emaillists/"
exports.addEmailToListApiEndPoint = "/bx_block_emaillists/emaillists/"
exports.addEmailToListApiEndPoint2 = "/add_user"
exports.updateEmailApiEndPoint2 = "/update_emaillist_user"
exports.deleteEmailDataApiEndPoint2 = "/destroy_emaillist_user"
exports.token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTEsImV4cCI6MTY5NTk2ODc1MiwidG9rZW5fdHlwZSI6ImxvZ2luIn0.OdgvelF77krvI8y2iNVGUwZjBnVNZMQvxGACpc4BPvsGmLlOKIZLZwlMsmGKGqCYsq9nfbQxoPf-MP6-d14c_A"
exports.searchByEmailListApiEndPoint = "/bx_block_emaillists/emaillists/search_emillist?search="
exports.searchAssociatedEmailfromListApiEndPoint = "/search_user?search="
exports.loginApiEndPoint = "/bx_block_login/logins"
exports.AddListText = "Add List";
exports.cancelBtnText = "Cancel";
exports.SaveBtnText = "Save";
exports.EditBtnText = "Edit";
exports.DeleteBtnText = "Delete";
exports.AddEmailBtnText = "Add Email";
exports.SubmitBtnText = "Submit";
exports.PreviousBtnText = "Previous";
exports.pageText = "Page";
exports.NextBtnText = "Next";
exports.LogoutBtnText = "Logout";
exports.EmailText = "Email";
exports.FullNameText = "Full Name";
exports.NoEmailText = "No emails exist in this email list."
exports.searchResultTxt = "Here are your search results";
exports.RecordNoText = "Records Not Found";


// Customizable Area End