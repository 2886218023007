export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const searchIcon = require("../assets/search-icon.png");
export const searchIconLogo = require("../assets/searchIcon.png");

export const icEdit = require("../assets/ic_edit.png");
export const icEmail = require("../assets/ic_email.png");
export const icremove = require("../assets/ic_remove.png");
export const ArrowUp = require("../assets/ic_up.png");
export const ArrowDown = require("../assets/ic_down.png");
export const ic_trash = require("../assets/ic_trash.png");
export const ic_play = require("../assets/ic_play.png");
export const ic_setting = require("../assets/ic_setting.png");
export const ic_trend_up = require("../assets/ic_trend_up.png");  
export const ic_trend_down = require("../assets/ic_trend_down.png");
export const ic_line_dark = require("../assets/ic_line_dark.png");
export const ic_line_white = require("../assets/ic_line_white.png");
export const ic_bar_dark = require("../assets/ic_bar_dark.png");
export const ic_bar_white = require("../assets/ic_bar_white.png");
export const ic_calendar = require("../assets/ic_calendar.png");
export const ic_pause = require("../assets/ic_pause.png");
export const bg_date = require("../assets/bg_date.png");
export const bg_date_end = require("../assets/bg_date_end.png");
