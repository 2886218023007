import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: String;
  searchText: string;
  helpCentreData: any;
  suggestedArticles: any;
  anchorEl: null | HTMLElement;
  selectedFileIndex: number | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HelpCentreDetailsController extends BlockComponent<Props, S, SS> {
  getHelpCentreDetailsApiCallId: any;
  searchDebounceTimer: NodeJS.Timeout | null = null; 

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      searchText: "",
      token: "",
      helpCentreData: null,
      suggestedArticles: [],
      anchorEl: null,
      selectedFileIndex: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    getStorageData("token").then((token) => {
      this.onGettingHelpCentreDetails(token);
      this.setState({token: token});
    });

    // Get search text from URL
    const urlParams = new URLSearchParams(window.location.search);
    const searchText = urlParams.get('searchText') || '';
    this.setState({ searchText });
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message API Recieved", JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getHelpCentreDetailsApiCallId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({helpCentreData: responseJson.article, suggestedArticles: responseJson.suggested_articles});
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    const prevId = prevProps.navigation?.getParam("id");
    const currentId = this.props.navigation?.getParam("id");
    
    if (prevId !== currentId) {
      this.onGettingHelpCentreDetails(this.state.token as string);
    }
  }

  onGettingHelpCentreDetails = (token: string) => {
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHelpCentreDetailsApiCallId = requestMessage.messageId;

    // Add search parameter if search text exists
    const endpoint = `/bx_block_help_centre/show?id=${this.props.navigation.getParam("id")}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  downloadFile = (item: { url: string, name: string }) => {
    try {
      // Basic URL validation
      const url = new URL(item.url);
      
      // Create link element
      const link = document.createElement('a');
      link.href = url.toString();
      link.download = item.name;
      link.target = '_blank'; // Opens in new tab if download fails
      link.style.display = 'none';

      // Attempt download
      document.body.appendChild(link);
      link.click();
      
      // Cleanup
      setTimeout(() => {
        document.body.removeChild(link);
      }, 100);
    } catch (error) {
      // Fallback: try opening in new tab
      if (typeof window !== "undefined") {
        window.open(item.url, '_blank');
      }
    }
  }

  handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedFileIndex: index
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
      selectedFileIndex: null
    });
  };

  handleDownload = (item: any) => {
    this.handleMenuClose();
    this.downloadFile(item);
  };
  // Customizable Area End
}
