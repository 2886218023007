import React from "react";
// Customizable Area Start
import { Box, Typography, styled, TextField, Stack, Button } from "@mui/material";
import Layout from "../../../components/src/Layout.web";
import { ic_back } from "../../../blocks/accountgroups/src/assets";
import SearchIcon from '@mui/icons-material/Search';
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";
import { empty_view } from "./assets";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer   
    <Layout navigation={this.props.navigation} currentRoute='Home'>
      <StyledContainer>
        <Box className="header-container">
          <img src={ic_back.default} style={{width: "28px", position: "absolute",  height: "28px", top: "30px"}} />
          <Box style={{marginLeft: "80px", display: "flex", flexDirection: "column"}}>
          <Typography variant="h5" fontWeight="bold" style={{
            fontSize: "24px",
            color: "#00184C",
          }}>
            Welcome back, {this.state.profile?.attributes?.first_name}!
          </Typography>
          <Typography style={{
            color: "#00184C",
            fontWeight: "400",
            fontSize: "16px",
          }} variant="body2" color="textSecondary">
            Take a look at your organization!
          </Typography>
          </Box>
          <Box className="header-top">
              <Box
                className="tour-search-item"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: "white",
                  height: "38px",
                  boxSizing: "border-box",
                  border: '1px solid #E2E8F0',
                  borderRadius: '0px',
                  padding: '4px 8px',
                  width: '250px',
                  borderBottom: "1px solid #C4C4C6",
                }}
              >
              <SearchIcon sx={{ color: '#0F172A', marginRight: '9px' }} />
               <TextField
                  placeholder="Search"
                  variant="standard"
                  size="small"
                  style={{height: "100%"}}
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: '14px',
                       height: "100%",   
                        color: '#00184C', 
                      fontFamily: "Inter, sans-serif",
                      fontWeight: 700,
                  },
                  }}
                  value={this.state.searchText}
                  fullWidth
                  onChange={this.onChangeSearch}
                />
              </Box>
          </Box>
        </Box>
        <Box sx={{
            backgroundImage: `url(${empty_view.default})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '8px',
            width: '100%',
            aspectRatio: '1.54',
            marginTop: '20px',
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            paddingBottom: "50px"
          }}>
            <div>
            <Typography style={{
              color: "#00184C",
              fontWeight: 700,
              fontSize: "48px",
              lineHeight: "58px",
            }}>
            Your dashboard is empty
            </Typography>
            <Typography style={{
              color: "rgba(51, 65, 85, 1)",
              fontWeight: 400,
              fontSize: "24px",
              lineHeight: "32px",
            }}>
            Start a new campaign to start collecting data.
            </Typography>
            <Button
              className="tour-get-started-item"
              onClick={() => this.props.navigation.navigate("Campaigns")}
              sx={{
                    width: '180px',
                    height: '44px',
                    background: 'linear-gradient(122.97deg, rgba(228, 201, 243, 1) -55.47%, #00184C 40.61%)',
                    borderRadius: '22px',
                    color: 'white',
                    border: "1px solid rgba(164, 223, 255, 1)",
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: 700,
                    marginTop: "10px"
                  }}
                >
                  Get Started
                </Button>
              </div>
          </Box>
      </StyledContainer>
    </Layout>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start


const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  "*": { fontFamily: "Inter, sans-serif" },

  "& *": {
    fontFamily: "Inter, sans-serif",
    boxSizing: "border-box",
  },
  '.header-container': {
    display: 'flex',
    backgroundColor: "#E9E9EA",
    marginTop: "20px",
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    justifyContent: "space-between",
  },
  '.header-top': {
    display: 'flex',
    flexDirection: "row",
    gap: "20px",
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: "20px",
  },
  '.header-actions': {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  '.table-container': {
    overflow: 'hidden',
    maxHeight: 600,
  },
  '.filter-button': {
    padding: theme.spacing(1),
    borderRadius: 0, 
    gap: "10px",
    width: "96px",
    height: "38px",
    borderBottom: "1px solid #C4C4C6",
    backgroundColor: "white",
  },
}));
// Customizable Area End
