import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  setCurrentStep?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: String;
  searchText: string;
  helpCentreData: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HelpCentreController extends BlockComponent<Props, S, SS> {
  getHelpCentreApiCallId: any;
  searchDebounceTimer: NodeJS.Timeout | null = null; 

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      token: "",
      searchText: "",
      helpCentreData: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    getStorageData("token").then((token) => {
      this.setState({token: token});
      this.onGettingHelpCentre(token);
    });
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recieved", JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getHelpCentreApiCallId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({helpCentreData: responseJson.data});
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
    }
    // Customizable Area End
  }

  // Customizable Area Start

  onGettingHelpCentre = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHelpCentreApiCallId = requestMessage.messageId;

    // Add search parameter if search text exists
    const endpoint = `/bx_block_help_centre/helpcentre?title=${this.state.searchText}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

    componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.searchText !== this.state.searchText) {
      if (this.searchDebounceTimer) {
        clearTimeout(this.searchDebounceTimer);
      }
      
      this.searchDebounceTimer = setTimeout(() => {
        this.onGettingHelpCentre(this.state.token as string);
      }, 500);
    }
  }

  onStartTour = () => {
    setStorageData("isTour", "false").then(() => {
      this.props.setCurrentStep(0)
      this.props.navigation.navigate("Home");
    });
  }
  // Customizable Area End
}
